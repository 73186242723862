import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  HashRouter,
} from "react-router-dom";

//component imports
import NavigationBar from "./components/navigation/NavigationBar";
import NavBar2 from "./components/navigation/NavBar2";
import IndexPage from "./pages/IndexPage/IndexPage";
import Footer from "./components/footer/Footer";

//css imports
import "../src/css/indexpage.css";
import "../src/css/navigationBar.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffff00",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <HashRouter>
          <div>
            {/* <NavigationBar /> */}
            <NavBar2 />
            <div>
              <Routes>
                <Route path="/" element={<IndexPage />} />
                {/* <Route path="/about" element={<AboutUs />} /> */}
              </Routes>
            </div>
            <Footer />
          </div>
        </HashRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { OnScrollLInk } from "../../actions/IndexAction";
const NavigationBarHook = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    navItems: [
      {
        item: "Home",
        route: "/banner",
      },
      { item: "About Us", route: "/#banner" },
      {
        item: "Services",
        route: "/#banner",
        sub: [
          { data: "About Us", route: "about", id: "#about" },
          { data: "Our Branch" },
          { data: "Our Milestones" },
          { data: "Meet the Team" },
          { data: "Industries we Serve" },
          { data: "Career" },
          { data: "Customer Testimonial" },
        ],
      },
      { item: "Contacts", route: "/#banner" },
    ],
  });
  const onClickMenu = (route) => {
    console.log(route);
    dispatch(OnScrollLInk(route));
  };
  return {
    ...state,
    onClickMenu,
  };
};

export default NavigationBarHook;

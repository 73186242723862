import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// for contact us
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

//import for slide
import AOS from "aos";
import "aos/dist/aos.css";

//countUp
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

//checkbox
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//image imports
import stripes from "../../assets/banners/stripes.png";
import site from "../../assets/banners/site.jpg";
import map from "../../assets/banners/map.png";

// card
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

//hook import
import IndexPageHook from "../../hooks/indexpageHook/IndexPageHook";

//media query
import { useTheme } from "@mui/material/styles";
import { CardContent, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import Services from "./Services";
// components
import Stats from "./Stats";

// For Textfield
const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: "white !important",
  },
  "& .Mui-focused": {
    backgroundColor: "white !important",
  },
  ".MuiFilledInput-multiline": {
    backgroundColor: "white !important",
  },
  "& .onhover": {
    backgroundColor: "white !important",
  },
});

const IndexPage = () => {
  // ==== for animation on slide
  AOS.init();

  const [counterOn, setCounterOn] = useState(false);
  const { ...param } = IndexPageHook();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const selectedLink = useSelector((state) => state.IndexReducer.selectedLink);
  const homeRef = useRef();
  const aboutRef = useRef();
  const servicesRef = useRef();
  // const contactRef = useRef();
  useEffect(() => {
    if (selectedLink === "Home") {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (selectedLink === "About Us") {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (selectedLink === "Services") {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // if (selectedLink === "Contacts") {
    //   contactRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  }, [selectedLink]);
  return (
    <div id="about">
      <Grid container spacing={1}>
        {/* BANNER */}
        <Grid item md={12} xs={12}>
          <div ref={homeRef} className="banner">
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <div className={isMobile ? "mobileHeroText" : "heroText"}>
                  <div>
                    <div className={isMobile ? "mobileYellow" : "yellow"}>
                      <Typography
                        className={isMobile ? "indexTest" : "indexStyle"}
                      >
                        Fulfilling <br />
                      </Typography>

                      <Typography
                        className={
                          isMobile ? "indexTestYellow" : "indexStyleYellow"
                        }
                        // sx={{ paddingLeft: "18px" }}
                      >
                        Future Energy
                      </Typography>
                    </div>
                    <Typography
                      className={isMobile ? "indexTest" : "indexStyle"}
                    >
                      Needs Sustainability.
                    </Typography>
                  </div>

                  <Typography sx={{ fontSize: "20px", py: 3 }}>
                    We're contributing to that effort by implementing
                    eco-friendlier methods <br /> that don't damage the
                    environment.
                  </Typography>

                  <Button
                    variant="contained"
                    sx={{
                      px: 5,
                      py: 2,
                      borderRadius: 0,
                      backgroundColor: "yellow",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "#FFED00",
                      },
                    }}
                  >
                    DISCOVER MORE
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {/* WHO WE ARE SECTION */}

        <Grid item md={12}>
          <div
            ref={aboutRef}
            className={isMobile ? "mobileSec2BG" : "section2BG"}
          >
            <div className={isMobile ? "section2Mobile" : "section2"}>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <div
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="800"
                    data-aos-delay={600}
                    className={isMobile ? "stripeMobile" : "stripe"}
                  >
                    <Grid container xs={6}>
                      <Grid item md={6} xs={12} sx={{ py: 2 }}>
                        <div
                          style={{
                            backgroundColor: "#000",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "20px",
                              py: 1,
                              // color: "#181823",
                              opacity: "90%",
                              color: "yellow",
                            }}
                          >
                            WHO WE ARE
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>

                    <Typography
                      className={isMobile ? "mobileTextTitle2" : "textTitle2"}
                      sx={{ paddingBottom: "20px" }}
                    >
                      Offering dependable and low-cost energy.
                    </Typography>

                    <Divider sx={{ backgroundColor: "black", height: "5px" }} />

                    <Typography sx={{ pt: 3, paddingBottom: "40px" }}>
                      Low-cost energy has become a significant concern in
                      today's world. There are various sources of low-cost
                      energy, including renewable sources such as wind and
                      solar, as well as traditional sources such as natural gas
                      and coal.
                      <br />
                      <br />
                      With increasing demand for energy worldwide, it is
                      critical to continue exploring and investing in low-cost
                      energy options to ensure that everyone has access to
                      affordable and reliable energy sources. Access to
                      affordable energy is crucial for economic growth and
                      development, especially for countries with limited
                      resources.
                    </Typography>

                    <Stack gap="16px">
                      {param.dataList.map((val, index) => {
                        return (
                          <>
                            <Stack direction="row" gap="16px">
                              {val.icon}
                              <Typography>{val.text}</Typography>
                            </Stack>
                          </>
                        );
                      })}
                    </Stack>
                    <div style={{ padding: "20px 0 50px 0" }}>
                      <Button
                        variant="contained"
                        sx={{
                          px: 4,
                          py: 1.3,
                          borderRadius: 0,
                          backgroundColor: "yellow",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "#FFED00",
                          },
                        }}
                      >
                        DISCOVER MORE
                      </Button>
                    </div>
                  </div>
                </Grid>

                {/* who we are image */}
                <Grid item md={5} xs={12}>
                  <div className={isMobile ? "mobileAbtDiv" : "abtDiv"}>
                    <img
                      alt="petroleum"
                      src={site}
                      className={isMobile ? "mobileAbtImg" : "abtImg"}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        {/* PRE WE SPREAD AROUND THE WORLD */}
        <Grid item md={12} xs={12}>
          <div style={{ backgroundColor: "black" }}>
            <Typography
              data-aos="fade-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="800"
              className={isMobile ? "mobileTextTitle3" : "textTitle2"}
              sx={{
                color: "white",
                padding: "50px",
                borderBottomStyle: "solid",
                borderBottomColor: "yellow",
                borderBottomWidth: " 5px",
                borderBottomRightRadius: "100px",
              }}
            >
              Our distribution capabilities <br /> span across many regions.
            </Typography>
          </div>
        </Grid>

        <Grid item md={12}>
          <div
            style={{
              padding: "40px 10px 40px 10px",
              backgroundColor: "rgba(255,255,255, 0.5)",
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={6}>
                <div className={isMobile ? "mobileWorldMap" : "worldMap"}>
                  <img
                    src={map}
                    style={{
                      width: "100%",
                      borderRadius: "16px",

                      objectFit: "cover",
                    }}
                  />
                </div>
              </Grid>

              {/* WE SPREAD AROUND THE WORLD */}

              <Grid item md={6}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <div className={isMobile ? "mobileMap" : "map"}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "24px",
                          py: 2,
                          color: "#181823",
                          opacity: "90%",
                        }}
                      >
                        {/* Who We Are? */}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "40px",
                          lineHeight: 1.05,
                          pb: 3,
                          textTransform: "capitalize",
                        }}
                      >
                        We Plan To Spread Around <br /> The World
                      </Typography>

                      <Divider
                        sx={{ backgroundColor: "yellow", height: "5px" }}
                      />

                      <Typography
                        sx={{ paddingTop: "20px", paddingBottom: "30px" }}
                      >
                        Our team of experts has a deep understanding of the
                        complexities of the petroleum industry. With our wealth
                        of experience and dedication to excellence, you can
                        trust us to provide reliable and cost-effective
                        petroleum solutions for your business.
                      </Typography>
                    </div>
                  </Grid>

                  <div className={isMobile ? "mobileStat" : "stat"}>
                    {/* <Grid container spacing={2}>
                      {param.stats.map((newStats, index) => {
                        const multiplier = index * 300;

                        return (
                          <Stats
                            delay={multiplier}
                            title={newStats.title}
                            number={newStats.number}
                          />
                        );
                      })}
                    </Grid> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {/* PRESERVE AND CONSERVE */}

        <Grid item md={12}>
          <div className="parallax">
            <Grid container spacing={1}>
              <Grid item md={6}>
                <div className={isMobile ? "mobilePreserve" : "preserve"}>
                  <Grid container xs={10}>
                    <Grid item md={6} xs={11} sx={{ py: 3 }}>
                      <div
                        style={{
                          backgroundColor: "#000",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "22px",
                            py: 1,
                            // color: "#181823",
                            opacity: "90%",
                            color: "yellow",
                          }}
                        >
                          Preserve and Conserve
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>

                  <Typography
                    className={isMobile ? "mobileTextTitle2" : "textTitle2"}
                    sx={{ paddingBottom: "20px" }}
                  >
                    A Vital Energy Resource For A Better Tomorrow
                  </Typography>
                  <Typography sx={{ paddingBottom: "40px" }}>
                    The petroleum distributor company plays a critical role in
                    providing the vital energy resource that powers our society
                    and enables us to build a better tomorrow.
                  </Typography>

                  <div
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="800"
                    // style={{ padding: "20 0 0px 0 !important" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        px: 4,
                        py: 1.3,
                        borderRadius: 0,
                        backgroundColor: "yellow",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#FFED00",
                        },
                      }}
                    >
                      DISCOVER MORE
                    </Button>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className={isMobile ? "mobileCleanCard" : "cleanCard"}>
                  <Stack gap="16px" sx={{ mt: 9, color: "white" }}>
                    {param.dataList.map((val, index) => {
                      return (
                        <>
                          <Card
                            className={
                              isMobile ? "mobileCleanEnergy" : "cleanEnergy"
                            }
                            sx={{
                              color: "white",
                              "&:hover": {
                                color: "yellow",
                                borderRightStyle: "solid",
                                borderRightColor: "yellow",
                                borderRightWidth: "5px",
                              },
                            }}
                          >
                            <CardContent
                              sx={{
                                padding: "0 !important",
                              }}
                            >
                              <Stack
                                direction="row"
                                gap="16px"
                                sx={{
                                  justifyContent: "left",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "30px",
                                    alignItems: "end",
                                  }}
                                >
                                  {val.icon2}
                                </Typography>

                                <Typography
                                  className={
                                    isMobile ? "mobileCleanText" : "cleanText"
                                  }
                                  // sx={{ fontSize: "20px" }}
                                >
                                  {val.text}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Card>
                        </>
                      );
                    })}
                  </Stack>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {/* POWERFUL SOLUTIONS FOR A SUSTAINABLE FUTURE */}

        <Grid item md={12}>
          <div ref={servicesRef} className={isMobile ? "mobileServ" : "serv"}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <div style={{ textAlign: "center" }}>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <Typography
                        className={isMobile ? "mobileTextTitle2" : "textTitle2"}
                        sx={{ pb: "20px" }}
                      >
                        Powerful solutions <br /> for a sustainable future
                      </Typography>
                    </Grid>
                    <Grid item md={2} />
                    <Grid item md={8} xs={12}>
                      <Divider
                        sx={{ backgroundColor: "black", height: "5px" }}
                      />
                      <Typography
                        sx={{
                          paddingTop: "20px",
                        }}
                      >
                        As a petroleum distributor, we offer our services across
                        a wide range of locations to ensure that our customers
                        have access to the products they need, wherever they
                        are.
                      </Typography>
                    </Grid>
                    <Grid item md={2} />
                  </Grid>
                </div>
              </Grid>

              {/* CARDS */}

              <Grid item md={12} xs={12}>
                <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                  <Grid container spacing={2}>
                    <Grid item md={1} />
                    <Grid item md={10} xs={12}>
                      <Grid container spacing={2}>
                        {param.cardsPetrol.map((val, index) => {
                          const multiplier = index * 300;
                          return (
                            <Services
                              delay={multiplier}
                              icon={val.icon}
                              title={val.title}
                              desc={val.desc}
                            />
                          );
                        })}
                      </Grid>
                    </Grid>
                    <Grid item md={1} />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default IndexPage;

import React, { useState } from "react";

// react icons
import { SiDevexpress } from "react-icons/si";
import { MdStairs } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { TbGrowth } from "react-icons/tb";
// import { MdEnergySavingsLeaf } from "react-icons/md";

// cardsPetrol icons
import { ImTruck } from "react-icons/im";
// import { BsClipboard2CheckFill } from "react-icons/bs";
import { FaRoad } from "react-icons/fa";
import { TbRoad } from "react-icons/tb";
import { BsTruck } from "react-icons/bs";
import { BsBarChartLine } from "react-icons/bs";

// import { BsClipboard2Check } from "react-icons/bs";

// contact icons
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

//icon imports
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import Email from "@mui/icons-material/Email";
const IndexPageHook = () => {
  const [state, setState] = useState({
    dataList: [
      {
        icon: <FileDownloadDoneIcon />,
        // icon2: <MdEnergySavingsLeaf />,
        text: "Clean energy for a bright future",
      },
      {
        icon: <FileDownloadDoneIcon />,
        icon2: <TbGrowth />,
        text: "Sustainable development",
      },
      {
        icon: <FileDownloadDoneIcon />,
        icon2: <BiWorld />,
        text: "Improving access to energy",
      },
    ],

    stats: [
      {
        title: "Years of Experience",
        number: "30",
      },
      {
        title: "Number of Clients",
        number: "100",
      },
      {
        title: "Finsihed Projects",
        number: "50",
      },
    ],

    cardsPetrol: [
      {
        icon: <BsTruck />,
        title: "Transportation",
        desc: "We offer transportation services to move the products to various locations using various modes of transportation",
      },
      {
        icon: <TbRoad />,
        title: "Delivery",
        desc: "We have delivery services which ensure that our customers receive their products on time and in the right quantities.",
      },
      {
        icon: <BsBarChartLine />,
        title: "Inventory Management",
        desc: "We manage inventory levels to ensure that the right amount of products is available for delivery at all times.",
      },
    ],

    contact: [
      {
        title: "Email",
        icon: <EmailIcon />,
        info: "----",
      },
      {
        title: "Mobile Number",
        icon: <PhoneAndroidIcon />,
        info: "+63 ----",
      },
      {
        title: "Telephone Number",
        icon: <PhoneIcon />,
        info: "(043) --- ---",
      },
      {
        title: "Address",
        icon: <LocationOnIcon />,
        info: "----",
      },
    ],
  });

  return { ...state };
};

export default IndexPageHook;

import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

//import assets
import petroleumWhite2 from "../../assets/logo/petroleumWhite2.png";
import gzoneLogo from "../../assets/logo/gzonepetroleumlogo.png";

//import css
import "../../css/indexpage.css";

//import hook
import NavigationBarHook from "../../hooks/navigationhook/NavigationBarHook";

//Media Query
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";

//import icons
import { ImMenu } from "react-icons/im";

const drawerWidth = 240;

const NavBar2 = (props) => {
  const { ...param } = NavigationBarHook();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={gzoneLogo} style={{ height: "120px" }} />
      <Divider />
      <List>
        {param.navItems.map((newNavItems, index) => {
          return (
            <ListItem
              onClick={() => param.onClickMenu(newNavItems.item)}
              key={index}
              disablePadding
            >
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={newNavItems.item} />
              </ListItemButton>
            </ListItem>
          );
        })}
        {/* {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{ backgroundColor: "#000", height: "70px" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <ImMenu />
            </IconButton>
            <Grid container>
              <Grid item md={2} xs={10}>
                <div style={{ pt: "10px", textAlign: "center" }}>
                  <img src={petroleumWhite2} style={{ height: "60px" }} />
                </div>
              </Grid>

              <Grid item md={10}>
                <Box
                  sx={{
                    textAlign: "right",
                    alignItems: "center",
                    justifyItem: "center",
                    height: "65px",
                    pt: "15px",
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  {param.navItems.map((newNavItems) => {
                    return (
                      <Button
                        onClick={() => param.onClickMenu(newNavItems.item)}
                        key={newNavItems.item}
                        sx={{ color: "#fff" }}
                      >
                        <Typography sx={{ fontWeight: "600" }}>
                          {newNavItems.item}
                        </Typography>
                      </Button>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default NavBar2;

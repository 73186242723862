import React from "react";

import Grid from "@mui/material/Grid"; // Grid version 1
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";

const Services = (props) => {
  const { ...param } = props;
  return (
    <>
      <Grid item md={4}>
        <Card
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="800"
          data-aos-delay={param.delay}
          sx={{
            // height: "400px",
            position: "relative",
            borderRadius: "15px",
            padding: "20px",
            backgroundColor: "black",
            color: "white",
            borderTopStyle: "solid",
            borderTopWidth: "5px",
            borderTopColor: "yellow",
            height: "100%",
          }}
        >
          <CardContent
            sx={{
              textAlign: "center",
            }}
          >
            <Stack
              direction="column"
              gap="16px"
              sx={{
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 40 }}>{param.icon}</Typography>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "600",
                }}
              >
                {param.title}
              </Typography>

              <Typography sx={{ fontSize: "16px" }}>{param.desc}</Typography>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Services;

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import React from "react";

// logo
import petroleumWhite2 from "../../assets/logo/petroleumWhite2.png";

// fb logo
import FacebookIcon from "@mui/icons-material/Facebook";

// hooks
import NavigationBarHook from "../../hooks/navigationhook/NavigationBarHook";
import IndexPageHook from "../../hooks/indexpageHook/IndexPageHook";
import { Stack } from "@mui/system";

// media query
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const { ...param } = NavigationBarHook();
  const { ...param2 } = IndexPageHook();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div
        className={isMobile ? "mobileFooter" : "footer"}
        // style={{ backgroundColor: "#000", padding: "50px 130px 50px 130px" }}
      >
        <Grid container spacing={5} md={12} xs={12}>
          <Grid item md={5} xs={12}>
            <img
              src={petroleumWhite2}
              style={{ width: "190px", cursor: "pointer" }}
            />
            <Typography sx={{ color: "white" }}>
              Greenzone Petroleum has a core focus on the transportation and
              delivery of petroleum products. By prioritizing efficiency,
              reliability, and safety, the company strives to provide
              outstanding service to its customers.
            </Typography>

            <Typography sx={{ color: "white", paddingTop: "20px" }}>
              {/* <FacebookIcon
                sx={{
                  fontSize: 40,
                  "&:hover": {
                    color: "yellow",
                    cursor: "pointer",
                  },
                }}
              /> */}
            </Typography>
          </Grid>

          {/* navigation */}
          <Grid item md={2} xs={12}>
            <Grid container>
              <Grid item md={1} xs={1}>
                <div
                  className={isMobile ? "mobileHeightSec" : "heightDef"}
                  // style={{ height: "250px" }}
                >
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    // color="secondary.main !important"
                    sx={{ backgroundColor: "yellow", width: "1px" }}
                  />
                </div>
              </Grid>
              <Grid item md={11} xs={11}>
                <Typography sx={{ color: "white", fontWeight: "600" }}>
                  SECTIONS
                </Typography>

                <List>
                  {param.navItems.map((newNavItems) => {
                    return (
                      <ListItem
                        sx={{
                          paddingBottom: "3px",
                          color: "white",
                          "&:hover": {
                            color: "yellow",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <ListItemText alignItems="center">
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="left"
                          >
                            <Typography>{newNavItems.item}</Typography>
                          </Stack>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Grid>

          {/* services */}
          <Grid item md={2} xs={12}>
            <Grid container>
              <Grid item md={1} xs={1}>
                <div
                  className={isMobile ? "mobileHeightServ" : "heightDef"}
                  // style={{ height: "250px" }}
                >
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    // color="secondary.main !important"
                    sx={{ backgroundColor: "yellow", width: "1px" }}
                  />
                </div>
              </Grid>
              <Grid item md={11} xs={11}>
                <Typography sx={{ color: "white", fontWeight: "600" }}>
                  SERVICES
                </Typography>

                <List>
                  {param2.cardsPetrol.map((newCardsPetrol) => {
                    return (
                      <ListItem
                        sx={{
                          paddingBottom: "3px",
                          color: "white",
                          "&:hover": {
                            color: "yellow",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <ListItemText alignItems="center">
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="left"
                          >
                            <Typography>{newCardsPetrol.title}</Typography>
                          </Stack>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Grid>

          {/* contact */}
          <Grid item md={3} xs={12}>
            <Grid container>
              <Grid item md={1} xs={1}>
                <div
                  className={isMobile ? "mobileHeightContact" : "heightDef"}
                  // style={{ height: "250px" }}
                >
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    // color="secondary.main !important"
                    sx={{ backgroundColor: "yellow", width: "1px" }}
                  />
                </div>
              </Grid>
              {/* <Grid item md={11} xs={11}>
                <Typography sx={{ color: "white", fontWeight: "600" }}>
                  CONTACT US
                </Typography>

                <List>
                  {param2.contact.map((newContact) => {
                    return (
                      <ListItem
                        sx={{
                          paddingBottom: "3px",
                          color: "white",
                        }}
                      >
                        <ListItemText alignItems="center">
                 
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="left"
                          >
                            <Typography sx={{ paddingRight: "10px" }}>
                              {newContact.icon}
                            </Typography>
                            <Typography>{newContact.info}</Typography>
                          </Stack>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div style={{ backgroundColor: "#333300", height: "70px" }}>
        <Typography
          sx={{
            color: "white",
            padding: "10px",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          Copyright 2023 Gzonetech
          <br />
          All Rights Reserved
        </Typography>
      </div>
    </>
  );
};

export default Footer;
